import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'

type TInitialState = {
  isAppIdle: boolean
  isBrowserSupported: boolean
  isNavigateAvailable: boolean
  navigateTo: string | null
  showUserTradesOnChart: boolean
}

const initialState: TInitialState = {
  isAppIdle: false,
  isBrowserSupported: true,
  isNavigateAvailable: true,
  navigateTo: null,
  showUserTradesOnChart: true,
}

const checkBrowserSupport = createAsyncThunk('app/checkBrowserSupport', async () => {
  try {
    return true
    // Uncomment to disable brave browser support
    // const isBrave = await (navigator as any).brave?.isBrave?.()
    // return !isBrave
  } catch (err) {
    return true
  }
})

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setAppIdleState: (state, { payload }: PayloadAction<boolean>) => {
      state.isAppIdle = payload
    },
    setBrowserSupportedState: (state, { payload }: PayloadAction<boolean>) => {
      state.isBrowserSupported = payload
    },
    setIsNavigateAvailable: (state, { payload }: PayloadAction<boolean>) => {
      state.isNavigateAvailable = payload
    },
    setNavigateTo: (state, { payload }: PayloadAction<string | null>) => {
      state.navigateTo = payload
    },
    setShowUserTradesOnChart: (state, { payload }: PayloadAction<boolean>) => {
      state.showUserTradesOnChart = payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(checkBrowserSupport.fulfilled, (state, { payload }) => {
      state.isBrowserSupported = payload
    })
  },
})

const {
  setAppIdleState,
  setBrowserSupportedState,
  setIsNavigateAvailable,
  setNavigateTo,
  setShowUserTradesOnChart,
} = appSlice.actions

export {
  appSlice,

  // Reducers
  setAppIdleState,
  setBrowserSupportedState,
  setIsNavigateAvailable,
  setNavigateTo,
  setShowUserTradesOnChart,

  // Thunks
  checkBrowserSupport,
}
